<template>
  <section class="m-setting-password m-scroll">
    <!-- 头部导航 -->
    <m-header title="Change Password"></m-header>

    <p class="title">New Password</p>

    <div class="input-cell">
      <input
        v-model="password"
        type="text"
        placeholder="8-20 digit numbers/letters/characters"
      />
    </div>

    <div class="footer">
      <van-button
        @click="onSubmit"
        round
        block
        type="info"
        native-type="submit"
        color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)"
        >Confirm</van-button
      >
    </div>
  </section>
</template>

<script>
import MHeader from "@/components/en/m-header.vue";
import { repass } from "@/api/en/mine.js";
export default {
  name: "Index",
  components: { MHeader },
  data() {
    return {
      password: "",
    };
  },

  methods: {
    // 点击保存
    onSave() {
      this.$notify({ type: "success", message: "保存成功" });
    },
    // 确定
    onSubmit() {
      if (this.password == "") {
        this.$notify({
          type: "warning",
          message: "Please enter a new password first",
        });
        return false;
      }
      let params = {
        password: this.password,
      };
      repass(params).then((res) => {
        if (res) {
          this.$notify({ type: "success", message: "Modify the success" });
          this.$router.go(-2);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.m-setting-password {
  width: 100%;
  height: 100%;
  background-color: #f5f9ff;
  padding-top: 44px;
  .title {
    margin: 30px 0 10px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    padding-left: 20px;
  }
  .input-cell {
    background: #fff;
    padding: 0 20px;
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 52px;
    > input {
      outline: none;
      border: none;
      font-size: 14px;
    }
    > input::placeholder {
      font-size: 14px;

      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 20px;
    }
  }
  .footer {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 38px;
  }
}
</style>